<template>
  <div class="preview-wps-content">
    <div class="pages" v-if="showPPTpages">
      <div
        :class="wpscurryPage !== 1 ? 'page-back' : 'page-no-back'"
        @click="setfloorbutton"
      >
        <i class="el-icon-caret-left"></i>
      </div>
      <div class="page-numbers" @click="showPagePreview">
        {{ wpscurryPage }}/{{ wpscount }}
      </div>
      <div
        :class="wpscurryPage == wpscount ? 'page-no-next' : 'page-next'"
        @click="setbutton"
      >
        <i class="el-icon-caret-right"></i>
      </div>
    </div>
    <div
      id="newwpsfileDialog"
      style="
        height: 100%;
        width: 100%;
        position: relative;
        box-sizing: border-box;
      "
    >
      <TeachingLession
        v-if="wpsSdkVersion === 1"
        ref="newwpsfileDialogIframe"
        :wpsFileId="'newwpsfileIframeId'"
        :wpsConfig="wpsConfig"
        :isCache="isCache"
        v-bind:wpsUrl="wpsReadUrl"
        v-bind:token="wpsReadtoken"
        v-bind:wpscurryPage="wpscurryPage"
        v-bind:element="'newwpsfileDialog'"
        @changewpscurryPage="changewpscurryPage"
        @setinitdata="setinitdata"
        @setbuttonstate="setbuttonstate"
        @initPageAction="setTeachingPageAction"
        @setCurrentCacheStatus="setCurrentCacheStatus"
      />
      <TeachingLessionV2
        v-if="wpsSdkVersion === 2"
        ref="newwpsfileDialogIframe"
        :wpsFileId="'newwpsfileIframeId'"
        :wpsConfig="wpsConfig"
        :isCache="isCache"
        v-bind:wpsParams="wpsReadParams"
        v-bind:token="wpsReadtoken"
        v-bind:wpscurryPage="wpscurryPage"
        v-bind:element="'newwpsfileDialog'"
        @changewpscurryPage="changewpscurryPage"
        @setinitdata="setinitdata"
        @setbuttonstate="setbuttonstate"
        @initPageAction="setTeachingPageAction"
        @setCurrentCacheStatus="setCurrentCacheStatus"
      />
    </div>
  </div>
</template>

<script>
import TeachingLession from "@/views/teaching/components/teachingLession.vue";
import { checkResourceAuth, webofficeUrl, getWPSParam } from "@/api/classroom";
import { getToken } from "@/core/auth";
import TeachingLessionV2 from "./components/teachingLessionV2";
export default {
  data() {
    return {
      wpsSdkVersion: 2,
      fileUrl: "",
      wpsConfig: "wpsConfigDialog",
      showPPTpages: false, // 判断是否为ppt显示右下角页码
      isCache: false, // 判断是否是缓存
      pagePreview: true, // 当前预览PPT的显示框状态
      wpsReadUrl: "", // 只读模式下wps url
      wpscount: "1", // 总页数
      wpscurryPage: 1, // 当前页码
      isAuth: false, // 文件是否有权限
      initbutton: false,
      floorstate: false,
      nextstate: false,
      wpsReadtoken: "",
      format: "",
      version: "",
      resourceId: "",
      isInnerIframe: false,
      courseId: "",
      resourceType: "",
      fileSource: "",
    };
  },
  components: {
    TeachingLession,
    TeachingLessionV2,
  },
  mounted() {
    this.isInnerIframe = window.self !== window.top;
  },
  created() {
    this.showPPTpages = this.$route.query.showPPTpages === "true";
    this.isCache = this.$route.query.isCache === "true";
    this.resourceId = this.$route.query.resourceId;
    this.version = this.$route.query.version;
    this.format = this.$route.query.format;
    this.courseId = this.$route.query.courseId;
    this.resourceType = this.$route.query.resourceType;
    this.fileSource = this.$route.query.fileSource;
    console.log("path========", this.$route.path);
    console.log("this.resourceId", this.resourceId);
    this.initWps();
  },
  methods: {
    async showPagePreview() {
      let _this = this;
      await _this[this.wpsConfig].ready();
      const app = _this[this.wpsConfig].Application;
      app.ActivePresentation.SlideShowSettings.SetMiniThumbnailVisible(
        this.pagePreview
      );
    },
    fullScreenHandle() {
      console.log("fullScreenHandle================");
    },
    initWps() {
      if (this.resourceId) {
        if (this.wpsSdkVersion === 1) {
          webofficeUrl({
            _w_file_id: this.resourceId,
            _w_file_format: this.format.toLowerCase(),
            _w_permission: "read",
            _w_file_version: this.version,
            _w_file_code: this.courseId + "-" + this.resourceType,
            _w_file_source: this.fileSource || "",
          }).then((res) => {
            this.isAuth = true;
            this.wpsReadUrl = res.data.wpsUrl;
            this.wpsReadtoken = res.data.token;
            // this.wpsEditUrl ="https://wwo.wps.cn/office/p/5?_w_fileid=5&_w_appid=4527d895d700434eb997857888ecddc3&_w_signature=hLKq9%2BILPx3wr1h9Zvmu622rhwc%3D"
          });
        } else {
          let params = {
            _w_file_code: this.courseId + "-" + this.resourceType,
            _w_file_format: this.format.toLowerCase(),
            _w_file_id: this.resourceId,
            _w_file_version: this.version,
            _w_isUpdate: 0,
          };
          getWPSParam(params).then((res) => {
            this.wpsReadParams = res.data;
            this.wpsReadtoken = getToken();
          });
        }
      } else {
        this.$message.warning("没有获取到文件");
      }
    },
    changewpscurryPage(data, type) {
      if (type == 1) {
        console.log("456465");
        this.wpscurryPage = data;
      }
      if (type == 2) {
        console.log("456465");
        this.wpscount = data;
      }
    },
    /**
     * 初始化状态
     */
    setinitdata(type) {
      console.log(type);
      this.initbutton = type;
    },
    /**
     * 改变按钮状态
     */
    setbuttonstate(type1, type2) {
      this.floorstate = type1;
      this.nextstate = type2;
    },
    async setfloorbutton() {
      let _this = this;
      await _this[this.wpsConfig].ready();
      const app = _this[this.wpsConfig].Application;
      // 演示文稿对象
      const presentation = await app.ActivePresentation;
      // 幻灯片对象
      const slides = await presentation.Slides;
      // 获取总页数
      const count = await slides.Count;
      // 获取当前页码
      const wpscurryPage = await app.ActivePresentation.SlideShowWindow.View
        .Slide.SlideIndex;
      _this.nextstate = true;
      if (wpscurryPage == 1) {
        this.floorstate = false;
      }
      if (wpscurryPage > 1) {
        if (wpscurryPage == 2) {
          await app.ActivePresentation.SlideShowWindow.View.GotoSlide(
            wpscurryPage - 1
          );
          this.floorstate = false;
        } else {
          await app.ActivePresentation.SlideShowWindow.View.GotoSlide(
            wpscurryPage - 1
          );
          this.floorstate = true;
        }
      }
    },
    // 设置当前文件缓存状态
    setCurrentCacheStatus(status) {
      if (this.isInnerIframe) {
        window.parent.postMessage(
          JSON.stringify({
            type: "AIYKT",
            data: {
              type: "CACHE_STATUS",
              status,
              resourceId: this.resourceId,
            },
          }),
          "*"
        );
      } else {
        this.$message.warning("当前不在iframe中");
      }
    },
    setTeachingPageAction() {
      if (this.isInnerIframe && !this.isCache) {
        window.parent.postMessage(
          JSON.stringify({
            type: "AIYKT",
            data: {
              type: "TEACHING_PAGE_ACTION",
              refresh: true,
            },
          }),
          "*"
        );
      } else {
        !this.isCache && this.$message.warning("当前不在iframe中");
      }
    },
    /**
     * 下一页
     */
    async setbutton() {
      let _this = this;
      await _this[this.wpsConfig].ready();
      const app = _this[this.wpsConfig].Application;
      // 演示文稿对象
      const presentation = await app.ActivePresentation;
      // 幻灯片对象
      const slides = await presentation.Slides;
      // 获取总页数
      const count = await slides.Count;
      // 获取当前页码
      const wpscurryPage = await app.ActivePresentation.SlideShowWindow.View
        .Slide.SlideIndex;
      _this.floorstate = true;
      if (count == wpscurryPage) {
        this.nextstate = false;
      }
      if (count > wpscurryPage) {
        if (count == wpscurryPage + 1) {
          await app.ActivePresentation.SlideShowWindow.View.GotoSlide(
            wpscurryPage + 1
          );
          this.nextstate = false;
        } else {
          await app.ActivePresentation.SlideShowWindow.View.GotoSlide(
            wpscurryPage + 1
          );
          this.nextstate = true;
        }
      }
    },
  },
};
</script>

<style lang="less" scoped>
.preview-wps-content {
  position: relative;
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  .pages {
    z-index: 999;
    position: fixed;
    bottom: 30px;
    right: 12px;
    width: 98px;
    height: 28px;
    border-radius: 5px;
    background: #fff;
    -webkit-box-shadow: 0px 3px 10px 0px rgba(66, 94, 195, 0.4);
    box-shadow: 0px 3px 10px 0px rgba(66, 94, 195, 0.4);
    padding: 4px 4px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    cursor: pointer;
    .page-back,
    .page-next {
      width: 28px;
      height: 28px;
      line-height: 28px;
      font-size: 24px;
      color: #5a53f5;
    }
    .page-no-back {
      width: 28px;
      height: 28px;
      line-height: 28px;
      font-size: 24px;
      color: #999;
      cursor: not-allowed;
    }
    .page-no-next {
      width: 28px;
      height: 28px;
      line-height: 28px;
      font-size: 24px;
      color: #999;
      cursor: not-allowed;
    }
    .page-numbers {
      font-size: 14px;
      color: #5a53f5;
      line-height: 28px;
    }
  }
  /deep/ .web-office-iframe {
    width: 100% !important;
    height: 100% !important;
  }
}
</style>
